import React, { useCallback, useEffect, useState } from "react";
import { getClient } from "./clients.resource.js";
import { redirectOrCatch } from "fetcher!sofe";
import ClientMenu from "./client-menu.component.js";
import { setRecentClients } from "./recent-clients.helper.js";
import { useWithUserAndTenant } from "cp-client-auth!sofe";
import { useParams } from "react-router-dom";
import { useClientQuery } from "clients-ui!sofe";
import { featureEnabled } from "feature-toggles!sofe";

export const FetchClient = () => {
  const [, tenant] = useWithUserAndTenant();

  if (tenant?.crm_status === "crm_hierarchy_complete" && featureEnabled("ft_crm")) {
    return <FetchClientNewCrm />;
  } else {
    return <FetchClientOldCrm />;
  }
};

function FetchClientNewCrm() {
  const [user, tenant] = useWithUserAndTenant();
  const { clientId } = useParams();

  const { client } = useClientQuery({
    clientId,
    includes: "users,clients,tags,client_for,client_sources,integrations,roles,general_assigned,contacts,client_groups",
  });

  useEffect(() => {
    if (client) {
      setRecentClients(client, user?.id);
    }
  }, [client, user]);

  if (!client) {
    return null;
  } else {
    return (
      <ClientMenu
        loggedInUser={user}
        tenant={tenant}
        client={client}
        refetchClient={() => {
          /** When removing ft_crm remove this method and all other occurrences in the children */
        }}
      />
    );
  }
}

// Remove when removing ft_crm
function FetchClientOldCrm() {
  const [user, tenant] = useWithUserAndTenant();
  const { clientId } = useParams();
  const [client, setClient] = useState(null);

  const refetchClient = useCallback(() => {
    getClient(clientId, true).subscribe((resp) => {
      setClient(resp);
      setRecentClients(resp, user?.id);
    }, redirectOrCatch());
  }, [user?.id, clientId]);

  useEffect(() => {
    if (clientId) {
      const sub = getClient(clientId).subscribe((resp) => {
        setClient(resp);
        setRecentClients(resp, user?.id);
      }, redirectOrCatch());
      window.addEventListener(`cp:clients:${clientId}-edited`, refetchClient);
      return () => {
        window.removeEventListener(`cp:clients:${clientId}-edited`, refetchClient);
        sub.unsubscribe();
      };
    }
  }, [clientId, user, refetchClient]);

  if (!client) {
    return null;
  } else {
    return <ClientMenu loggedInUser={user} tenant={tenant} client={client} refetchClient={refetchClient} />;
  }
}
